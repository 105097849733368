import React from "react";
import "../style.scss"

const Search = ({ onSubmit, formData, setOpen, closeSearch, onChangeSearch,onClickSearch }) => {
    const {
        register,
        handleSubmit,
        errors,
    } = formData;
    return (
        <div className={`search blog-search header-search 999 flex-item`}>
            <div className="search-form-wrapper">
                <button onClick={closeSearch}
                        className={'modal-video-close-btn'}
                        aria-label='Close'/>
                <form className={'form search-form'}
                      id="blog-search-form"
                      role="search"
                      onSubmit={handleSubmit(onSubmit)}
                >
                    <div className={`input-wrapper${(errors.search ? ' invalid' : '')}`}
                    onClick={onClickSearch}
                         onKeyDown={onClickSearch}
                         role="presentation">
                        <input
                            type="text"
                            name={'search'}
                            placeholder="Find interesting articles"
                            id="blog-search-input"
                            autoComplete="off"
                            ref={register({
                                required: {
                                    value: true,
                                    message: 'Please add you search request.'
                                },
                            })}
                            onChange={onChangeSearch}
                        />
                        {errors.search?.message &&
                        <div className={'error-badge'}>
                            <i className={'mc-icon mc-icon--caution'}/>
                        </div>}
                        {errors.search?.message &&
                        <div className={'error-message'}>
                            {errors.search?.message}
                        </div>}
                    </div>
                    <label className={'label-container transition-link mc-button-secondary'}
                           htmlFor="blog-search-input">
                        <button
                            type={'submit'}
                            className={'button-image button-search'}
                            aria-label="Find"
                        />
                    </label>
                </form>
            </div>
        </div>
    )
}

export default Search
import React from "react";
import sanitizeHtml from 'sanitize-html';
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { normalizePath } from "../../utils/get-url-path";
import sanitizeOptions from "../../helper/sanitizeOptions";

export const Item = ({
                         uri,
                         title,
                         date,
                         author,
                         postFields,
                         featuredImage
                     }) => {
    const sanitizeOpt = sanitizeOptions();
    return (
        <>
            <li className={`flex-item blog-item`}>
                <AniLink
                    className={'blog-item-link'}
                    paintDrip
                    duration={1}
                    hex="#ed5d2b"
                    to={normalizePath(uri)}>
                    <div className={'post-image full-width'}>
                        {!!featuredImage?.node?.remoteFile?.childImageSharp && (
                            <img
                                src={
                                    featuredImage.node.remoteFile.childImageSharp.fluid.src
                                }
                                alt={featuredImage.node.remoteFile.childImageSharp.fluid.originalName}
                            />
                        )}
                    </div>
                    <div className={'description-wrapper'}>
                        <h4 className={'post-title h4-title black'}>
                            {title}
                        </h4>
                        <div className="post-info paragraph-secondary gray">
                            {date && <span className="post-time">{date}</span>}
                            {author?.node?.name &&
                            <span className="post-author">{author.node.name}</span>}
                        </div>
                    </div>
                    {!!postFields?.homeText && (
                        <div className={'post-content paragraph-primary black'}>
                            <span
                                dangerouslySetInnerHTML={{ __html: sanitizeHtml(postFields.homeText, sanitizeOpt.contentData) }}/>
                        </div>
                    )}
                </AniLink>
            </li>
        </>
    )
}

export default Item
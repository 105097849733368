import * as constants from "../helper/constants";

export default function setFilterTitleSearch(results, query) {
    let withoutSearch = [];
    let resSearchArray = [];
    if (results && query) {
        withoutSearch = results.filter((el) => el.title.toLowerCase().indexOf(query.toLowerCase()) === -1);
        resSearchArray = results
            .filter((el) => el.title.toLowerCase().indexOf(query.toLowerCase()) > -1)
            .concat(withoutSearch)
            .filter((el, i) => (i <= constants.BLOG_SEARCH_MAX_ITEMS));
        return resSearchArray;
    }
    return results;
}
import React, { useState, useEffect } from "react";
import { graphql, navigate } from "gatsby";
import ReactPaginate from "react-paginate";
import Layout from "../components/layout";
import Subscribe from "../components/subscribe";
import SidebarPostsBlock from "../components/posts-list/sidebar-post-block";
import prevNextPaginationButtons from "../helper/prevNextPaginationButtons";
import Search from "../components/search";
import { Item } from "../components/search/Item";
import { WithoutResults } from "../components/search/WithoutResults";
import { ItemPost } from "../components/posts-list/ItemPost";
import { useForm } from "react-hook-form";
import { useFlexSearch } from 'react-use-flexsearch';
import setFilterTitleSearch from "../helper/setFilterTitleSearch";

export default ({
                    data: {
                        allWpPost,
                        wpPage: {
                            seo, additionalMetaTag, title
                        },
                        localSearchPosts
                    }, pageContext
                }) => {
    const sidebarNumberPosts = 3;
    const mainNodes = allWpPost?.nodes || [];
    const sidebarNodes = allWpPost?.nodes.slice(sidebarNumberPosts) || [];
    //Pagination
    const prevButton = 'blog-previous';
    const nextButton = 'blog-next';
    const keyPagination = process.env.KEY_PAGINATION_BLOG;
    const [paginateButtons, setPaginateButtons] = useState(false);
    useEffect(() => {
        const prevButtonEl = document.getElementsByClassName(prevButton);
        const nextButtonEl = document.getElementsByClassName(nextButton);
        const paginateButtonsEl = { prevButtonEl, nextButtonEl };
        setPaginateButtons(paginateButtonsEl);
    }, [prevButton, nextButton]);
    // Set metadata
    const seoData = { ...{ seo, pageContext }, additionalMetaTag, title };
    // Search
    const [storeData, setStoreData] = useState({});
    const [indexData, setIndexData] = useState('[[{"0":[]},{"0": []}],{"0": [{"0": [""]}]}, [""]]');
    const [firstState, setFirstState] = useState(true);
    const [open, setOpen] = useState(false);
    const { publicIndexURL, publicStoreURL } = localSearchPosts;
    const [query, setQuery] = useState('');
    let results = useFlexSearch(query, indexData, storeData);
    let titleResult = setFilterTitleSearch(results, query);
    const { register, handleSubmit, errors, reset } = useForm({
        mode: "onSubmit",
    });
    const formData = {
        register,
        handleSubmit,
        errors,
    };
    const onSubmit = (data) => {
        setQuery(data.search);
        setOpen(true);
    };
    const closeSearch = () => {
        setQuery('');
        setOpen(false);
        reset();
    };

    const getStoreData = () => {
        fetch(publicStoreURL
            , {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        )
            .then((r) => r.json())
            .then(myJson => {
                setStoreData(myJson);
            });
    };

    const getIndexData = () => {
        fetch(publicIndexURL)
            .then((r) => r.text())
            .then(text => {
                setIndexData(text);
            });
    };

    const onChangeSearch = () => {
        setOpen(true);
    };

    const onClickSearch = () => {
        if (firstState) {
            getStoreData();
            getIndexData();
            setFirstState(false);
        }
        setOpen(true);
    };

    return (
        <Layout
            setClass={'blog-page-wrapper'}
            seoData={seoData}>
            <div className={'blog-page blog-category'}
                 property={'disablePreFooter'}>
                <div className={`page-title-container full-width flex-al__c-jc__sb ${open ? 'open' : ''}`}>
                    <div className={'page-title flex-item'}>
                        <h1 className={'h1-title black'}>
                            <span>{title}</span>
                        </h1>
                    </div>
                    <Search
                        formData={formData}
                        onSubmit={onSubmit}
                        setOpen={setOpen}
                        closeSearch={closeSearch}
                        onChangeSearch={onChangeSearch}
                        onClickSearch={onClickSearch}
                    />
                </div>
                {(Array.isArray(titleResult) &&
                    (query !== '')) ?
                    <div className={'page-content flex-justify-sb search'}>
                        <div className={'page-main'}>
                            <ul className={'blog-list flex-justify-sb full-width'}>
                                {(titleResult.length) ? (
                                    titleResult.map((result, i) => (
                                            <Item
                                                key={i + result?.uri}
                                                uri={result?.uri}
                                                title={result?.title}
                                                date={result?.date}
                                                author={result?.author}
                                                postFields={result?.postFields}
                                                featuredImage={result?.featuredImage}
                                            />
                                        )
                                    )) : (
                                    <li className={'without-results'}>
                                        <WithoutResults/>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div> :
                    <div className={'page-content flex-justify-sb'}>
                        <div className={'page-main'}>
                            <ul className={'blog-list flex-justify-sb full-width'}>
                                {mainNodes.map((post, i) => (
                                        <ItemPost
                                            i={i}
                                            key={i + post?.uri}
                                            uri={post?.uri}
                                            title={post?.title}
                                            date={post?.date}
                                            author={post?.author}
                                            postFields={post?.postFields}
                                            featuredImage={post?.featuredImage}
                                            tags={post?.tags}
                                        />
                                    )
                                )}
                            </ul>
                            {pageContext && pageContext.totalPages > 1 && (
                                <div className={'pagination-wrapper'}>
                                    <ReactPaginate
                                        previousClassName={prevButton}
                                        nextClassName={nextButton}
                                        previousLabel={
                                            pageContext?.page !== 1 && <span className={'pager-arrow arrow-left'}/>
                                        }
                                        nextLabel={
                                            pageContext?.totalPages !== pageContext.page && (
                                                <span className={'pager-arrow arrow-right'}/>
                                            )
                                        }
                                        onPageChange={({ selected }) => {
                                            const page = selected + 1
                                            const path = page === 1 ? `/${keyPagination}/` : `/${keyPagination}/${page}/`
                                            navigate(path)
                                        }}
                                        disableInitialCallback
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={pageContext.totalPages}
                                        marginPagesDisplayed={1}
                                        pageRangeDisplayed={2}
                                        containerClassName={"pager blog-pager flex-justify-sb pager-list flex-container"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                        initialPage={pageContext.page - 1}
                                        pageClassName={"pager-item flex-center"}
                                        hrefBuilder={(page) => {
                                            prevNextPaginationButtons(paginateButtons);
                                            return `/${keyPagination}/${page}/`;
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    </div>}
                <div className="bottom-subscribe">
                    <Subscribe/>
                </div>
            </div>
        </Layout>
    )
}

export const query = graphql`
    fragment Thumbnail on File {
        childImageSharp {
            fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_tracedSVG
            }
        }
    }

    query BlogHomePage($offset: Int!, $perPage: Int!) {
        allWpPost(
            limit: $perPage
            skip: $offset
            sort: { fields: date, order: DESC }
        ) {
            nodes {
                tags {
                    nodes {
                        link
                        name
                    }
                }
                id
                uri
                title
                date(formatString: "DD MMM YYYY")
                author {
                    node {
                        name
                    }
                }
                postFields {
                    homeText
                }
                featuredImage {
                    node {
                        sourceUrl
                        remoteFile {
                            childImageSharp {
                                fluid(quality: 100) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }

        wpPage(slug: {eq: "blog"}){
            title
            seo {
                title
                metaDesc
                canonical
            }
            additionalMetaTag {
                keywords
                ogDescription
                ogTitle
                ogType
                ogImage {
                    mediaItemUrl
                }
                twitterImage {
                    mediaItemUrl
                }
                twitterTitle
                twitterDescription
            }
        }

        localSearchPosts {
            publicIndexURL
            publicStoreURL
        }
    }
`
